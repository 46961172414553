import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

const routes: Routes = [
  {
    path: "",
    redirectTo: "wissen",
    pathMatch: "full",
  },
  {
    path: "wissen",
    loadChildren: () =>
      import("./wissen/wissen.module").then((m) => m.WissenModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    HttpClientModule,
    BrowserAnimationsModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
